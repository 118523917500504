import React from 'react';

import _ from 'lodash';

import LaboratoryCard from './Card';

const LABORATORY_ORDER = {
  Equipment: 'Equipment',
  Techniques: 'Techniques',
};

const LaboratoryList = ({ labs }) => {
  const byPosition = _.groupBy(labs, ({ node: laboratory }) => laboratory.frontmatter.position);

  return (
    <div className="laboratory__wrapper">
      { Object.keys(LABORATORY_ORDER).map(position => (
        <div className="laboratory__list__wrapper" key={ position }>
          { !!byPosition[position] && (
            <>
              <h3 className="page__subtitle">{ position }</h3>
              <div className="laboratory__list">
                { byPosition[position].map(({ node: laboratory }) => (
                  <LaboratoryCard
                    key={ laboratory.id }
                    lab={ laboratory }
                  />
                )) }
              </div>
            </>
          ) }

        </div>
      )) }
    </div>
  );
};

export default LaboratoryList;
