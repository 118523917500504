import React from 'react';

const LaboratoryCard = ({ lab }) => (
  <div className="laboratory__card">
    { !!lab.frontmatter.image.childImageSharp && (
      <a href={ lab.fields.slug } target="_self" className="laboratory__card__img__link">
        <img className="laboratory__card__img" src={ lab.frontmatter.image.childImageSharp.fluid.src } alt={ `laboratory - ${lab.frontmatter.title}` } />
      </a>
    ) }
    <div className="laboratory__card__content">
      <div className="laboratory__card__data">
        <h3 className="laboratory__card__title">
          { !!lab.frontmatter.url && lab.frontmatter.url.substring(0, 1) !== '/' && (
            <a href={ lab.frontmatter.url } rel="noreferrer" target="_blank">
              { lab.frontmatter.title }
            </a>
          ) }
          { !!lab.frontmatter.url && lab.frontmatter.url.substring(0, 1) === '/' && (
            <a href={ lab.frontmatter.url } target="_self">
                { lab.frontmatter.title }
            </a>
          ) }
          { !lab.frontmatter.url && (
            <a href={ lab.fields.slug } target="_self">
              { lab.frontmatter.title }
            </a>
          ) }
        </h3>
        <p className="laboratory__card__desc">{ lab.frontmatter.description }</p>
      </div>

      { !!lab.frontmatter.url && lab.frontmatter.url.substring(0, 1) !== '/' && (
        <a href={ lab.frontmatter.url } rel="noreferrer" target="_blank" className="laboratory__card__cta">
          Read More
        </a>
      ) }
      { !!lab.frontmatter.url && lab.frontmatter.url.substring(0, 1) === '/' && (
        <a href={ lab.frontmatter.url } target="_self" className="laboratory__card__cta">
          Read More
        </a>
      ) }
      { !lab.frontmatter.url && (
        <a href={ lab.fields.slug } target="_self" className="laboratory__card__cta">
          Read More
        </a>
      ) }
    </div>
  </div>
);

export default LaboratoryCard;
