import React from 'react';

import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';

import SEO from 'Components/SEO';

import LaboratoryList from 'Components/laboratory/List';

export default ({ data }) => {
  const { edges: laboratory } = data.allMarkdownRemark;
  const labDescription = data.description;

  return (
    <PageWrapper>
      <SEO title="Laboratory" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">Laboratory</h1>
        { !!labDescription && (
          <div className="page__content page__content--laboratory" dangerouslySetInnerHTML={ { __html: labDescription.html } } />
        ) }
        <LaboratoryList labs={ laboratory } />
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "laboratory"},
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            position
            description
            url
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    description: markdownRemark(
      frontmatter: {
        templateKey: {eq: "laboratory_description"},
      }
    ) {
      html
    }
  }
`;
